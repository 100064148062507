<script setup>
import { ref } from 'vue'
defineProps({
    classes: String,
})

let color = ref(true)

function changeColor() {
    color.value = !color.value
}

</script>
<template>
    <a href="https://www.instagram.com/groenland2023/" target="_blank" rel="noopener noreferrer"
        class="w-fit h-fit mx-auto mt-5 transition">
        <div @mouseenter="changeColor" @mouseleave="changeColor">

            <svg v-show="color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 533.33 533.33" fill="white"
                :class="classes">
                <g>
                    <g>
                        <path
                            d="M266.67,0c-72.42,0-81.5,.31-109.95,1.6-28.38,1.29-47.77,5.8-64.73,12.39-17.54,6.81-32.41,15.93-47.23,30.76-14.83,14.82-23.94,29.7-30.76,47.23C7.41,108.95,2.9,128.34,1.6,156.72c-1.3,28.44-1.6,37.52-1.6,109.95s.31,81.5,1.6,109.95c1.3,28.38,5.8,47.77,12.4,64.73,6.81,17.54,15.93,32.41,30.76,47.23,14.83,14.82,29.7,23.94,47.23,30.76,16.96,6.59,36.35,11.1,64.73,12.39,28.44,1.3,37.52,1.6,109.95,1.6s81.5-.31,109.95-1.6c28.38-1.29,47.77-5.8,64.73-12.39,17.54-6.81,32.41-15.93,47.23-30.76,14.82-14.83,23.94-29.7,30.76-47.23,6.59-16.96,11.1-36.35,12.39-64.73,1.3-28.44,1.6-37.52,1.6-109.95s-.31-81.5-1.6-109.95c-1.29-28.38-5.8-47.77-12.39-64.73-6.81-17.54-15.93-32.41-30.76-47.23-14.83-14.82-29.7-23.94-47.23-30.76-16.96-6.59-36.35-11.1-64.73-12.39-28.44-1.3-37.52-1.6-109.95-1.6Zm0,48.05c71.2,0,79.64,.27,107.76,1.56,26,1.19,40.12,5.53,49.52,9.18,12.45,4.84,21.33,10.62,30.66,19.95,9.33,9.33,15.11,18.22,19.95,30.66,3.65,9.4,8,23.52,9.18,49.52,1.28,28.12,1.56,36.55,1.56,107.76s-.27,79.64-1.56,107.76c-1.19,26-5.53,40.12-9.18,49.52-4.84,12.45-10.62,21.33-19.95,30.66-9.33,9.33-18.21,15.11-30.66,19.95-9.4,3.65-23.52,8-49.52,9.18-28.11,1.28-36.55,1.56-107.76,1.56s-79.64-.27-107.76-1.56c-26-1.18-40.12-5.53-49.52-9.18-12.45-4.84-21.33-10.62-30.66-19.95-9.33-9.33-15.11-18.21-19.95-30.66-3.65-9.4-8-23.52-9.18-49.52-1.28-28.12-1.56-36.55-1.56-107.76s.27-79.64,1.56-107.76c1.19-26,5.53-40.12,9.18-49.52,4.84-12.45,10.62-21.33,19.95-30.66,9.33-9.33,18.22-15.11,30.66-19.95,9.4-3.65,23.52-8,49.52-9.18,28.12-1.28,36.55-1.56,107.76-1.56" />
                        <path
                            d="M266.67,355.56c-49.09,0-88.89-39.8-88.89-88.89s39.8-88.89,88.89-88.89,88.89,39.8,88.89,88.89-39.8,88.89-88.89,88.89Zm0-225.83c-75.63,0-136.94,61.31-136.94,136.94s61.31,136.94,136.94,136.94,136.94-61.31,136.94-136.94-61.31-136.94-136.94-136.94m174.35-5.41c0,17.67-14.33,32-32,32s-32-14.33-32-32,14.33-32,32-32,32,14.33,32,32" />
                    </g>
                </g>
            </svg>

            <img v-show="!color" src="../assets/logo-insta-color.svg" :class="classes" alt="">
        </div>
    </a>
</template>


