<script setup>
import { onMounted } from 'vue';
import Logo from "./components/Logo.vue";
import Button from "./components/Button.vue";
import Insta from './components/Insta.vue';
import Twint from './components/Twint.vue';
import Mail from './components/Mail.vue';

onMounted(() => {
  document.title = "Jeunesse du CAS Montreux-Jaman"
});
</script>

<template>
  <div
    class="w-full min-h-screen bg-[url('./assets/background2-min.jpg')] bg-no-repeat bg-center bg-cover bg-fixed text-center">
    <div
      class="sm:flex sm:content-center sm:items-center p-x-auto w-full min-h-screen overflow-auto backdrop-blur-sm backdrop-brightness-75">
      <div class="flex flex-col justify-center content-center w-full h-fit py-5">
        <Logo class="sm:w-56 w-2/6 mx-auto mb-6" color="white" />
        <h2 class="text-white text-3xl sm:mx-20 mx-5">Bienvenue sur la page de la jeunesse du Club Alpin
          de Montreux et Jaman&nbsp;!
        </h2>
        <p class="text-white text-lg mt-5 sm:mx-20 mx-5">Si vous voulez nous soutenir pour notre prochaine expédition,
          vous trouverez toutes les infos ci-dessous&nbsp;!
        </p>
        <div class="flex flex-col sm:flex-row items-center gap-8 mx-auto mt-5 mb-8 sm:mb-5">
          <Button value="Dossier sponsoring (Français)" link="https://cas-mtx-jmn.ch/Expe2023-sponso-final.pdf" />
          <Button value="Dossier sponsoring (Anglais)" link="https://cas-mtx-jmn.ch/Expe2023-sponso-final-en.pdf" />
          <Button value="Flyer Récapitulatif" link="https://cas-mtx-jmn.ch/Flyer-expe23.jpg" />
        </div>
        <div class="hidden sm:flex gap-10 mx-auto">
          <Insta classes="w-14" />
          <Twint />
          <Mail classes="w-14" />
        </div>
        <div class="flex flex-col sm:hidden mx-auto">
          <div class="flex">
            <Insta classes="w-14" />
            <Mail classes="w-14" />
          </div>
          <Twint />
        </div>
      </div>
    </div>
  </div>
</template>