<script setup>
import { ref } from 'vue'
defineProps({
    classes: String,
})

let color = ref('white')

function changeColor() {
    if (color.value == 'white') {
        color.value = '#3864ed'
    } else {
        color.value = 'white'
    }
}
</script>

<template>
    <a href="mailto:expe23@cas-mtx-jmn.ch" target="_blank" rel="noopener noreferrer"
        class="w-fit h-fit mx-auto mt-5 scale-125 transition" @mouseenter="changeColor" @mouseleave="changeColor">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="color"
            :class="classes">
            <path stroke-linecap="round" stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
        </svg>
    </a>
</template>